<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          Produk Pascabayar
          <div class="card-header-actions"></div>
        </CCardHeader>
        <CCardBody>
          <vue-good-table
            :rows="rows"
            :columns="columns"
            :isLoading="isLoading"
            :pagination-options="{
              enabled: true,
            }"
            :search-options="{
              enabled: true,
            }"
          >
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'updated_at'">
                <span>
                  {{ timestampModify(props.row.updated_at) }}
                </span>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <CBadge :color="props.row.status ? 'success' : 'danger'">
                  <span>
                    {{ props.row.status ? "Aktif" : "Tidak Aktif" }}
                  </span>
                </CBadge>
              </span>
              <span v-else-if="props.column.field == 'pruduct_active'">
                <CBadge
                  :color="props.row.pruduct_active ? 'success' : 'danger'"
                >
                  <span>
                    {{ props.row.pruduct_active ? "Aktif" : "Tidak Aktif" }}
                  </span>
                </CBadge>
              </span>
            </template>
          </vue-good-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import axios from "../../apis/api";

import moment from "moment";
import "moment/locale/id";

export default {
  name: "Breadcrumbs",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "SKU",
          field: "sku",
        },
        {
          label: "Nama",
          field: "product_name",
        },
        {
          label: "Brand",
          field: "brand",
        },
        {
          label: "Kategori",
          field: "category",
        },
        {
          label: "Harga",
          field: "price",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Aktif",
          field: "pruduct_active",
        },
        {
          label: "Deskripsi",
          field: "desc",
        },

        {
          label: "Update",
          field: "updated_at",
        },
      ],
      rows: [],
    };
  },
  methods: {
    timestampModify(timestamp) {
      return moment(timestamp).fromNow();
    },
    async loadItems() {
      this.isLoading = true;
      try {
        let response = await axios.get("/price_list", {
          params: {
            type: "postpaid",
          },
        });
        this.rows = response.data.data;
      } catch (error) {
        if (typeof error.response.data.message !== "undefined") {
          await this.$notify({
            type: "error",
            text: error.response.data.message,
          });
        }
        this.rows = [];
      }
      this.isLoading = false;
    },
  },
  async created() {
    await this.loadItems();
  },
};
</script>
